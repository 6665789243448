.wrap-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

.padding20 {
  padding: 20px;
  text-align: center;
 
}



